import React, { useEffect, useRef } from 'react';
import SectionHeading from '../Common/SectionHeading';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_accessibility from 'highcharts/modules/accessibility';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules'
import Link from 'next/link';

const PayAsUse = ({title=null, shortDescription=null}) => {
    if (typeof Highcharts === 'object') {
        HC_accessibility(Highcharts);
    }

    const data1 = [
        ['Pipeline Insight', 20],
        ['Epidemiology Insight', 15],
        ['Rare Disease Insight', 5],
        ['Market Insight', 60],
    ];
    
    const data2 = [
        ['Oncology', 70],
        ['Neurodegenerative', 20],
        ['Ophthalmology', 5],
        ['Dermatology', 3],
        ['Respiratory', 2],
    ];

    // Common accessibility configuration
    const accessibilityOptions = {
        accessibility: {
            enabled: true,
            description: 'Pie chart showing distribution of different insights and therapeutic areas',
            announceNewData: {
                announcementFormatter: function (allSeries, newSeries, newPoint) {
                    if (newPoint) {
                        return `New point added: ${newPoint.name} with value ${newPoint.y}%`;
                    }
                    return false;
                }
            }
        }
    };

    const options1 = {
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Insight Distribution',
        },
        plotOptions: {
            pie: {
                colors: ['#67b7dc', '#6794dc', '#6771dc', '#1b5df3'],
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.percentage:.1f}%'
                }
            },
        },
        series: [
            {
                name: 'Insights',
                data: data1,
            },
        ],
        ...accessibilityOptions
    };

    const options2 = {
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Therapeutic Areas',
        },
        plotOptions: {
            pie: {
                colors: ['#1b5df3', '#6794dc', '#6771dc', '#8067dc', '#a367dc'],
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.percentage:.1f}%'
                }
            },
        },
        series: [
            {
                name: 'Therapeutic Areas',
                data: data2,
            },
        ],
        ...accessibilityOptions
    };

    return (
        <>  
            <section id="packages" className="reviews-sec bg-image">
                <div className="flex flex-col justify-center w-11/12 mx-auto my-6 xl:max-w-screen max-w-screen-2xl lg:my-10">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 lg:col-span-4">
                            <p className='mb-0'>Pay as You Use</p>
                            <SectionHeading title={title} shortDescription={shortDescription} customClass="text-left"/>
                            <Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}} className='bg-[#1b5df3] btn'>Learn More</Link>
                        </div>
                        <div className="col-span-12 lg:col-span-8">


                            <Swiper
                                modules={[Navigation, Autoplay]}
                                speed={300}
                                parallax={true}
                                loop={true}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                    },
                                }}
                            >
                                <SwiperSlide >
                                    <div>
                                        <HighchartsReact highcharts={Highcharts} options={options1} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div>
                                        <HighchartsReact highcharts={Highcharts} options={options2} />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PayAsUse;
